import React from "react"
import Container from "../container"

import "./anmeldelse.scss"

interface AnmeldelseSingleProps {
  anmelder: string
  text: string
}
const AnmeldelseSingle = ({
  anmelder,
  text
}:AnmeldelseSingleProps) => {
  return (
    <div className="anmeldelse__single">
      <div dangerouslySetInnerHTML={{__html: text}} className="anmeldelse__text"/>
      <div className="anmeldelse__anmelder">
        {anmelder}
      </div>
    </div>
  )
}

const anmeldelser = [
  {
    "anmelder": "Karsten Koed, Præsident - Kongens Lyngby Rotary Klub",
    "text": "<p>Så fik vi lige bragt perspektiv i de sidste 60 års øst/ vest relation og sikkerhedspolitik på en spændende og udfordrende time med Niels Jensen.</p><p>Niels Jensen førte os med sikker hånd, stor historisk viden og fremrettet tænkning igennem de grundlæggende tænkninger, strategier og planer med uhyggelig præcision og perspektivering, både i forhold til vores nutid og ikke mindst fremtid. </p> <p> Niels Jensen står også med sin nye bog ved sine holdninger, og dokumentere med research fakta og personlig erfaring fra efterretningstjenesten hvilke levende gør fortællingen. </p> <p> Vi kan varmt anbefale Niels Jensens foredrag og vil anbefale at også bliver tid til opklarende spørgsmål. Det vil skabe en god debat yderligere vidensformidling. </p>"
  },
  {
    "anmelder": "Jess Nørgaard, Hemmingway club, Hillerød",
    "text": "<p>Hemingway club i Hillerød fandt foredraget fra Niels Jensen om den Hemmelige krig, særdeles vedkommende og velunderbygget.</p><p>Foredraget var historisk og aktuelt fast forankret også i lyset af den aktuelle Ukraine konflikt. Som fortæller var Niels Jensen meget vidende og entusiastisk, og han kunne krydre historien med mange spændende anekdoter.</p>"
  },
  {
    "anmelder": "Bente Jeppesen, AOF, Sydvest Sjælland",
    "text": '<p>"Niels Jensen har afholdt sit foredrag "Ivan og Putin ved hvor du bor" bade i Slagelse og Ringsted. Han er en meget dygtig foredragsholder og som tidligere efterretningsofficer har han fuldstændig styr pà tidligere højt klassificerede efterretningsrapporter i perioden med Den kolde Krig.<p></p>Et foredrag der er historisk - men ligeså godt kunne være fra i dag. Absolut anbefalelsesværdigt!"</p>'
  }
]
const Anmeldelse = () => {
  return (
    <div className="anmeldelse">
      <Container>
        <div className="anmeldelse__inner">
          <h2 className="anmeldelse__titel">Anmeldelser af foredrag</h2>
          {anmeldelser.map((single, index) => (
            <AnmeldelseSingle key={`single-${index}`} anmelder={single.anmelder} text={single.text}/>
          ))}
        </div>
      </Container>
    </div>
  )
}

export default Anmeldelse