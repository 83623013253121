import React from "react"
import Container from "../container"

import "./anmeldelseBog.scss"

interface AnmeldelseBogSingleProps {
  anmelder: string
  text: string
}
const AnmeldelseBogSingle = ({
  anmelder,
  text
}:AnmeldelseBogSingleProps) => {
  return (
    <div className="anmeldelse-bog__single">
      <div dangerouslySetInnerHTML={{__html: text}} className="anmeldelse-bog__text"/>
      <div className="anmeldelse-bog__anmelder">
        {anmelder}
      </div>
    </div>
  )
}

const anmeldelser = [
  {
    "anmelder": "Tommy P. Christensen, kultur- og militærhistoriker ",
    "text": "<p>Erfaren mand er værd at gæste, og Niels Jensen springer nu ud på Kriminalforlaget (under Frydenlundkoncernen) som gl. efterretningsmand. [...] Det gør ham i stand til at give en rigtig kyndig og god indføring i, hvad der i bogens titel kaldes den Den hemmelige krig – hvor den interesserede læser får et interessant indblik i klassificering, ”The Race to the Baltics”, taktisk e-tjeneste og strategisk varsling. Mange gode historier fra den kolde krig lækkes, og billedmaterialet er velvalgt og med flere pletskud. [...] Konkluderende må det siges, at forfatter og forlaget har fået skabt en letlæst og kyndig introduktion til et stort stofområde, der bestemt ikke er blevet mindre betydningsfuldt siden 24. februar 2022.</p>"
  },
  {
    "anmelder": "Jacob Hornemann, cand.mag., historiker",
    "text": "<p>Bogen rummer et væld af konkrete, ofte meget dramatiske episoder under Den kolde Krig, hvor Warszawapagt-enheder og danske og NATO-enheder krydsede klinger i den efterretningskrig, som er bogens hovedtema. For første gang gives offentligheden med denne populærvidenskabelige bog et levende indtryk af de konkrete trusler, som sovjetisk, polsk og østtysk militær stillede Danmark over for under Den kolde Krig. [...] Forfatteren skriver livligt og i et letlæseligt sprog og har forsynet bogen med nyttige ordforklaringer. Bogen er en gave til alle, der interesserer sig for sikkerhedspolitik og Den kolde Krig.</p>"
  },
  {
    "anmelder": "Alf Gørup Theilgaard, lektor i dansk og billedkunst",
    "text": "<p>I en tid med krig og kriser i Europa giver det ekstra god mening at forholde sig til historien og de konflikter, der har fyldt i nyere tid. Alene af den grund kan Niels Jensens gennemgang af Danmark som del af Den kolde Krig være oplagt læsning. [...] Tidligere strengt fortrolige og ofte hemmelige arkiver er i dag åbne, så egentlige afsløringer bringes ikke, men ikke desto mindre er der nok af interessante sager at forholde sig til. Niels Jensen har fundet meget guld i gemmerne, der viser omfanget af den overvågning, Danmark blev udsat for.</p>"
  },
  {
    "anmelder": "Dines Bogø, historiker, forfatter, foredragsholder - Dragør",
    "text": "<p>Det er længe siden, at jeg har læst en så spændende bog som DEN HEMMELIGE KRIG.</p>"
  },
  {
    "anmelder": "Allan Huglstad, forfatter, lokalhistoriker og major - tidligere Forsvarsakademiet",
    "text": '<p>Niels Jensens bog "Den Hemmelige Krig" med undertitlen "Truslerne mod Danmark 1949-1990" omhandler efterretningsarbejdet omkring Danmark og Østersøen under Den kolde Krig. Her fortælles om NATOs og Warszawapagtens(WAPA) efterretningsindsamling - populært kaldet "spionage" - i et let tilgængeligt sprog. Bogen bygger - naturligvis på tilgængelige kilder, dvs. afklassificeret materiale og indeholder en mængde oplysninger, der har været ukendt for langt de fleste. Vægten ligger især på Sjælland, Lolland-Falster og Bornholm, der lå udsat for en WAPA invasion, og som især var udsat for spionage fra Sovjetunionen, Polen og DDR.</p><p>Bogens styrke er et samlet overblik over efterretningskrigen - et overblik, som jeg ikke har set i andre udgivelser.</p>'
  },
  {
    "anmelder": "Henrik Hjorth, Leder af Flyvevåbnets Historiske Samling",
    "text": '<p>Bogen er meget velskrevet, utrolig korrekt og meget informativ. Min far, der var pilot i Flyvevåbnet 1952-1970, er meget begejstret.</p>'
  },
  {
    "anmelder": "Lennart Rasmusson, forhenværende oberstløjtnant i det svenske flyvevåben FLYGVAPNET, divisionschef ved Skånska Flygflottiljen og chef for Krigsflygskolan F5/F10 på Ljungbyhed.",
    "text": '<p>Jag har med glädje läs boken DEN HEMMELIGE KRIG, som är välskriven och mycket interessant. Delen som handlar om Sverige och vårt underrättelsesarbete viser på inträngande kunskarp och er helt korrekt.</p>'
  }
]
const AnmeldelseBog = () => {
  return (
    <div className="anmeldelse-bog">
      <Container>
        <div className="anmeldelse-bog__inner">
          <h2 className="anmeldelse-bog__titel">Anmeldelser af Den Hemmelige Krig</h2>
          {anmeldelser.map((single, index) => (
            <AnmeldelseBogSingle key={`single-${index}`} anmelder={single.anmelder} text={single.text}/>
          ))}
        </div>
      </Container>
    </div>
  )
}

export default AnmeldelseBog