import * as React from "react"
import { HeadFC, useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import {SEO} from "../components/seo"
import Intro from "../components/intro"
import Radio from "../components/radio"
import Artikler from "../components/artikler"
import AnmeldelseBog from "../components/anmeldelseBog"
import Anmeldelse from "../components/anmeldelse"
import Boeger from "../components/boeger"
import Book from "../components/book"
import Kontakt from "../components/kontakt"

const intro = {
  heading: "Presse og anmeldelser",
  description: "<h2>Lyt og læs om Niels Jensen i pressen.</h2>"
}


const Presse = () => {
  
  return (
      <Layout>
        <Intro 
          heading={intro?.heading} 
          description={intro?.description} 
          sm 
        />
        <Radio/>
        <Artikler/>
        <AnmeldelseBog/>
        <Anmeldelse/>
        <Boeger/>
        <Book/>
        <Kontakt/>
    </Layout>
  )
}

export default Presse


export const Head: HeadFC = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )
  return (
    <SEO title={`Presse | ${site.siteMetadata.title}`} description="Lyt til interviewet med Niels Jensen i Radio 4's udsendelse 'Frontlinjen' eller læs had pressen har skrevet."/>
  )
}
