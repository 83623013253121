import React from "react"

import Container from "../container"
import { BookIcon } from "../icon"
import {MSG4, MSG5} from "../../const"

import "./boeger.scss"

import Img1 from "../../images/den_hemmelige_krig.jpg";
import Img2 from "../../images/for_dannebrogs_aere.jpg";
import Img3 from "../../images/hjaelpen_til_soenderjylland.jpg";

interface KontaktProps {
  invert?: boolean
}
const Boeger = ({invert}: KontaktProps) => {
  return (
    <div className={invert ? "boeger boeger--invert" : "boeger"}>
      <Container>
        <div className="boeger__inner">
          <h2>Bøger</h2>
          <h4>Niels Jensen har skrevet mere end 40 non-fiction bøger. "Hjælpen til Sønderjylland" udkommer April 2023 og "Den hemmelige krig" kan købes via boghandlerne eller direkte fra forfatteren.</h4>
          <div className="boeger__billeder">
            <img src={Img3} className="boeger__img" alt="Hjælpen til Sønderjylland. Forfatter: Niels Jensen"/>
            <img src={Img1} className="boeger__img" alt="Den hemmelige krig. Forfatter: Niels Jensen"/>
            <img src={Img2} className="boeger__img" alt="For Dannebrogs Ære. Forfatter: Niels Jensen"/>
          </div>
          <p className="boeger__btns">
            <a 
              className={`btn${invert ? ' btn--dark' : ''}`}
              href={`mailto:niels.jensen@jetra.dk?subject=${MSG5}`}
            >
              Køb "Hjælpen til Sønderjylland"
            </a>

            <a 
              className={`btn${invert ? ' btn--dark' : ''}`}
              href={`mailto:niels.jensen@jetra.dk?subject=${MSG4}`}
            >
              Køb "Den hemmelige Krig"
            </a>
          </p>
          <BookIcon size="sm" green/>
        </div>
      </Container>
    </div>
  )
}
export default Boeger;