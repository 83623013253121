import React from "react"
import Container from "../container"
import Img1 from "../../assets/berlinske_logo.svg";
import Img2 from "../../assets/sn_logo.svg";
import Img3 from "../../assets/bornholms_tidende_logo.svg";

import "./artikler.scss"

interface ArtikelProps {
  avis: string
  journalist: string
  dato: string
  titel: string
  link: string
  logo: number
}
const Artikel = ({
  avis,
  journalist,
  dato,
  titel,
  link,
  logo
}:ArtikelProps) => {
  let LOGO = null;
    switch(logo) {
      case 0:
        LOGO = <Img1 title={avis}/>;
        break;
      case 1:
        LOGO = <Img2 title={avis}/>;
        break;
      default:
        LOGO = <Img3 title={avis}/>;
    }
  return (
    <div className="artikel">
      <div className="artikel__logo">{LOGO}</div>
      <div className="artikel__text">
        <p className="artikel__dato">{dato}</p>
        <h3 className="artikel__titel">
          <a href={link} className="normal" rel="noopener noreferrer" target="_blank">
            {titel}
          </a>
        </h3> 
        
        <p className="artikel__journalist">Skrevet af {journalist}</p>
        <a href={link} className="normal anim artikel__link" rel="noopener noreferrer" target="_blank">Læs artikel</a>
      </div>
    </div>
  )
}

const artikler = [
  {
    "avis": "Berlinske Tidende", 
    "journalist": "Birgitte Erhardtsen",
    "dato": "Jan 02. 2023",
    "titel": "»James Bond« var direktør i ØK, men så forsvandt han med firmaets penge.",
    "link": "https://www.berlingske.dk/business/james-bond-var-direktoer-i-oek-men-saa-forsvandt-han-med-firmaets-penge"
  },
  {
    "avis": "SN (Det grønne område)",
    "journalist": "Pernille Borenhoff",
    "dato": "14. december 2022",
    "titel": "Lokal forfatter: Den kolde krig spøger igen.",
    "link": "https://www.sn.dk/lyngby-taarbaek-kommune/lokal-forfatter-den-kolde-krig-spoeger-igen/"
  },
  {
    "avis": "Bornholms tidende",
    "journalist": "Torben Østergaard Møller",
    "dato": "21. november 2022",
    "titel": "Den kolde krig var varmere end som så.",
    "link": "https://tidende.dk/kultur/den-kolde-krig-var-varmere-end-som-saa/127668"
  }
]
const Artikler = () => {
  return (
    <div className="artikler">
      <Container>
        <div className="artikler__inner">
          <h2>Artikler</h2>
          <p>Læs hvad pressen skriver om Niels Jensen og hans bog "Den hemmelige krig".</p>
          <div className="artikler__kontainer">
            {artikler && artikler.map((single:any, index) => (
              <Artikel 
                key={`single-${index}`} 
                avis={single.avis} 
                journalist={single.journalist}
                dato={single.dato}
                titel={single.titel}
                link={single.link}
                logo={index}
              />
            ))}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Artikler