import React from "react"
import { Link } from "gatsby"

import "./radio.scss"

import Container from "../container"

const om = () => {
  return (
    <div className="radio">
      <Container>
        <div className="radio__inner">
          <div className="radio__text">
            <h2>Radio4 - Frontlinjen</h2>
            <h4>"VI KAN IKKE KØRE I MØRKE ELLER SKYDE DRONER - MEN DET MÅ VI IKKE TALE OM"</h4>
            <p>Hæren bøvler med køretøjer og luftværn, der har henholdsvis problemer i mørket og med droner, men er det overhovedet noget, vi bør tale om i offentligheden? Det skal det handle om i dagens Frontlinjen, hvor vi også vender en ny bog om truslerne mod Danmark under den kolde krig, om det politiske dødvande og den konsekvens, det kan få for forsvarsforliget og slutteligt sagen mod den mistænkte pirat, ”Lucky”.</p>
            <ul>
              <li><b>Dato:</b> 01.12.22</li> 
              <li><b>Vært:</b> Peter Ernstved Rasmussen.</li>
              <li><b>Varighed:</b> 55 minutter</li>
            </ul>
          </div>
          <div className="radio__embed">
            <iframe 
              src="https://embed.radio4.dk/?gid=44598&time=1080"
              className="radio__player"
              width="100%" 
              height="180px" 
              frameBorder="0" 
              scrolling="no" 
              title="Radio4 embedded audio player"
            ></iframe>
          </div>
          <div>
            <a
              href="https://www.radio4.dk/program/frontlinjen/?gid=44598&title=vi-kan-ikke-kre-i-mrke-eller-skyde-droner-men-det-m-vi-ikke-tale-om"
              className="btn btn--dark"
              rel="noopener noreferrer"
              target="_blank"
            >
              Fuld optagelse
            </a>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default om;
