import React from "react"
import {MSG1, MSG3} from "../../const"

import Container from "../container"
import "./book.scss"


const Book = () => {
  return (
    <div className="book">
      <Container>
        <div className="book__inner">
          <h2>Organiser et foredrag</h2>
          <p>Hvis du er interesseret i at organisere et foredrag kan du booke Niels direkte på <a className="normal anim white" href={`mailto:niels.jensen@jetra.dk?subject=${MSG1}`}>niels.jensen@jetra.dk</a>.
          </p>
          <div className="book__btns">
            <a className="btn" href={`mailto:niels.jensen@jetra.dk?subject=${MSG1}`}>Book nu!</a>
          </div>
          <p>Det er yderligere muligt at booke via ARTE Booking på telefon <a className="normal anim white" href="tel:38481400">38481400</a> eller på mail <a className="normal anim white" href={`mailto:booking@artebooking.dk?subject=${MSG3}`}>booking@artebooking.dk</a>.</p>
        </div>
      </Container>
    </div>
  )
}
export default Book;